@import '~modern-normalize/modern-normalize.css';
body {
  font-family: 'Montserrat', sans-serif;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: #1c658e;
}
